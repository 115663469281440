import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { bus } from "@/main";


const DEFAULT_FORM_STATE = {
  name: null,
  country_id: undefined,
  state_id: undefined,
  city: null,
  phone_no: null,
  company_profile_id: undefined,
  address: null,
  map: null,
  _method: 'post',
};

const DEFAULT_FILTER_STATE = {
  name: null,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Property Name",
    sortable: false,
    value: 'name',
  },
  {
    text: "Comapny Name",
    sortable: false,
    value: 'company',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  data() {
    return {
      operationTitle: 'Properties',
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      filters: { ...DEFAULT_FILTER_STATE },
      listUrl: '/properties',
      columns: COLUMN_DEFINATION(this),
      dropdowns: {
        companyProfiles: [],
        countries: [],
        states: [],
        propertyTypes: [],
      },
    };
  },
  methods: {
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: this.formFields.id ? 'properties/update' : 'properties/create',
          method: 'post',
          data: this.formFields,
        });

        const { data } = response.data;

        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.$store.dispatch('getPropertyList');
        bus.$emit("check-selected-property");
        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/properties/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
          this.$store.dispatch('getPropertyList');
          window.location.href = '/';
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/properties/detail/${ id }`,
        });
        const { data } = response.data;
        this.formFields = {
          ...data,
          company_profile_id: data.company ? data.company.id : undefined
        };
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async getCompanyProfiles() {
      try {
        const response = await request({
          url: '/dropdowns/all/company/profiles',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.companyProfiles = data.map((item) => {
          return {
            value: item.id,
            text: item.company_name,
          };
        });
      } catch (e) {

      }
    },
    async getCountry() {
      try {
        const response = await request({
          url: '/dropdowns/countries',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.countries = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getState() {
      try {
        const response = await request({
          url: '/dropdowns/states',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.states = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
            country_id: item.country_id,
          };
        });
      } catch (e) {

      }
    },
    async getPropertyTypes() {
      try {
        const response = await request({
          url: '/dropdowns/property/types',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.propertyTypes = data.map((item) => {
          return {
            value: item.id,
            text: item.title,
          };
        });
      } catch (e) {

      }
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "propertyList" });
    },
  },
};
