<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{formFields.id ? `Edit ${operationTitle}` : `Add ${operationTitle}`}}
          </template>
          <template v-slot:toolbar>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="rooms-operation">
                <b-container fluid>
                  <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                    <b-row>
                      <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                                label="Name Of property *"
                                label-for="name"
                                :invalid-feedback="formErrors.first('name')"
                            >
                              <b-form-input
                                  id="name"
                                  v-model="formFields.name"
                                  type="text"
                                  :state="((formErrors.has('name') ? false : null))"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4"
                                 v-if="$global.hasPermission('companyprofilesview') && !$global.parentUserExist()">
                            <b-form-group
                                label="Company Profile *"
                                label-for="company_profile_id"
                                :invalid-feedback="formErrors.first('company_profile_id')"
                            >
                              <b-form-select
                                  id="company_profile_id"
                                  v-model="formFields.company_profile_id"
                                  type="text"
                                  :options="dropdowns.companyProfiles"
                                  :state="((formErrors.has('company_profile_id') ? false : null))"
                              />
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row><!--/b-row-->
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                                label="Country *"
                                label-for="country_id"
                                :invalid-feedback="formErrors.first('country_id')"
                            >
                              <b-form-select
                                  v-model="formFields.country_id"
                                  :options="dropdowns.countries"
                                  :state="((formErrors.has('country_id') ? false : null))"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                label="State *"
                                label-for="state_id"
                                :invalid-feedback="formErrors.first('state_id')"
                            >
                              <b-form-select
                                  v-model="formFields.state_id"
                                  :options="dropdowns.states"
                                  :state="((formErrors.has('state_id') ? false : null))"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                label="City *"
                                label-for="city"
                                :invalid-feedback="formErrors.first('city')"
                            >
                              <b-form-input
                                  id="city"
                                  v-model="formFields.city"
                                  type="text"
                                  :state="((formErrors.has('city') ? false : null))"
                              >
                              </b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                                label="Property Type *"
                                label-for="property_type_id"
                                :invalid-feedback="formErrors.first('property_type_id')"
                            >
                              <b-form-select
                                  id="property_type_id"
                                  :options="dropdowns.propertyTypes"
                                  v-model="formFields.property_type_id"
                                  :state="((formErrors.has('property_type_id') ? false : null))"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                label="Phone no *"
                                label-for="property_type_id"
                                :invalid-feedback="formErrors.first('phone_no')"
                            >
                              <b-form-input
                                id="phone_no"
                                v-model="formFields.phone_no"
                                type="text"
                                :state="((formErrors.has('phone_no') ? false : null))"
                              >
                              </b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                                label="Address *"
                                label-for="address"
                                :invalid-feedback="formErrors.first('address')"
                            >
                              <b-form-textarea
                                  id="address"
                                  v-model="formFields.address"
                                  type="text"
                                  :state="((formErrors.has('address') ? false : null))"
                              >
                              </b-form-textarea>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                              label="Google Map"
                              label-for="map"
                              :invalid-feedback="formErrors.first('map')"
                            >
                              <b-form-input
                                id="city"
                                v-model="formFields.map"
                                type="text"
                                :state="((formErrors.has('map') ? false : null))"
                              >
                              </b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <hr />
                        <b-row class="operation-footer">
                          <b-col sm="12">
                            <b-button
                                size="sm"
                                type="submit"
                                variant="primary"
                                :disabled="global.pendingRequests > 0"
                            >
                              <i v-show="global.pendingRequests > 0"
                                 class="fa fa-spin fa-spinner"></i>
                              <i class="fa fa-save fa-sm"></i>
                              Save
                            </b-button>

                            <b-button variant="danger" class="ml-3"
                                      size="sm"
                                      @click="handleOperationClose()">
                              <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
              </div><!--/.rooms-operation-->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
  import KTCard from "@/view/content/Card.vue";
  import moduleMixin from "./module.mixin";
  import { mapGetters, mapState } from "vuex";

  export default {
    mixins: [moduleMixin],
    components: {
      KTCard,
    },
    mounted() {
      if ( this.$route.params && this.$route.params.id ) {
        this.handleEditOperation(this.$route.params.id);
      }

      if ( this.$global.hasPermission("companyprofilesview") ) {
        this.getCompanyProfiles();
      }
      this.getCountry();
      this.getState();
      this.getPropertyTypes();
    },
    computed: {
      ...mapState([
        'global',
      ]),
      ...mapGetters({ getActiveProperty: 'getActiveProperty' }),
    },
  };
</script>
